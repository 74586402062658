import React from 'react';
import { Hero } from 'carbonate-ui';
import Hint from '../components/Hint';
import Layout from '../components/Layout';

import config from '../config.js';
import products from '../collections/products.json';

export default (props) => {
  const dpsg = 'https://www.kdpproductfacts.com';

  return (
    <Layout href={props.location.href} head={config.site.head}>
      <div className="page-content">
        <Hero
          bgDesktop={config.site.heroImage}
          bgMobile={config.site.heroImageMobile}
        >
          <Hint
            link="#"
            scrollTo=".blurb.section"
          />
        </Hero>

        <section className="blurb section">
          <div className="container">
            <h1 className="blurb__title">{config.site.blurb.title}</h1>
            <p className="blurb__text">{config.site.blurb.text}</p>
          </div>
        </section>

        <section className="products section">
          <div className="container">
            <h3 className="products__title">{config.site.products.title}</h3>
            <div className="products__container">
              {products.map(product => (
                <div
                  className="product"
                  key={`product-${product.slug}`}
                >
                  <a
                    className="product__link"
                    href={`${dpsg}/en/product/${product.data.misc['Product Code']}`}
                    target="_blank"
                  >
                    <img
                      className="product__img"
                      src={`${dpsg}/smedia/kdpproductfacts.com/www/product_images/medium/${product.data.misc['Product Code']}.png`}
                      alt={product.data.misc['Product Name']}
                    />
                    <span className="product__name">{product.data.misc['Product Name']}</span>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
    );
  };
